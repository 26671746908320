import React from 'react';
import {dashConfig} from './dash';
import {guideConfig} from './guide';
import {errorPagesConfigs} from './errorPages';
import {authRouteConfig} from './auth';
import {initialUrl} from '../shared/constants/AppConst';
import {profileConfig} from './profile';
import {ObjectDetectionConfig} from './project';
import Error403 from './errorPages/Error403';
import Error404 from './errorPages/Error404';

const authorizedStructure = {
  fallbackPath: '/signin',
  unAuthorizedComponent: <Error403 />,
  routes: [...profileConfig, ...ObjectDetectionConfig],
};

const unAuthorizedStructure = {
  fallbackPath: initialUrl,
  routes: [...authRouteConfig],
};

const anonymousStructure = {
  routes: [
    errorPagesConfigs.concat([
      {
        path: '*',
        exact: true,
        element: <Error404 />,
      },
    ]),
    ...dashConfig,
    ...guideConfig,
  ],
};

export {authorizedStructure, unAuthorizedStructure, anonymousStructure};
