import {
  ADD_WORKSPACE,
  GET_WORKSPACE,
  UPDATE_WORKSPACE,
  DELETE_WORKSPACE,
  GET_WORKSPACES,
  ADD_PROJECT,
  GET_PROJECT,
  GET_PROJECTS,
  UPDATE_PROJECT,
  DELETE_PROJECT,
  ADD_TEST,
  GET_TEST,
  UPDATE_TEST,
  DELETE_TEST,
  FETCH_SUCCESS,
  FETCH_ERROR,
  SET_PROJECT,
  GET_TESTS,
} from '../../shared/constants/ActionTypes';
import Api from '../../@crema/services/ApiConfig';
import jwtAxios from '../../@crema/services/auth/jwt-auth/jwt-api';
import {appIntl} from '../../@crema/utility/helper/Utils';
import {random} from 'lodash';

export const addWorkSpace = ({name}) => {
  const {messages} = appIntl();
  return async (dispatch) => {
    try {
      const offset = new Date().getTimezoneOffset() * 60000;
      const today = new Date(Date.now() - offset);

      const result = await jwtAxios.put('/workspace', {
        name: name,
        createdAt: today.toISOString(),
      });
      console.log(result);

      if (result.status == 200) {
        dispatch({type: FETCH_SUCCESS});
        dispatch(getWorkSpaces());
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: messages['message.somethingWentWrong'],
        });
      }
    } catch (error) {
      console.log(error);
      dispatch({type: FETCH_ERROR, payload: error.message});
    }

    const item = [
      {
        name: name,
        id: random(0, 100000).toString(),
      },
    ];
    dispatch({type: ADD_WORKSPACE, payload: item});
  };
};
export const getWorkSpace = (workspace) => {
  return (dispatch) => {
    dispatch({type: GET_WORKSPACE, payload: workspace});
  };
};
export const updateWorkSpace = (id, workspace) => {
  return async (dispatch) => {
    await Api.post(`/workspace/${id}`, workspace);
    dispatch(getWorkSpaces());
  };
};
export const deleteWorkSpace = (id) => {
  return async (dispatch) => {
    await Api.delete(`/workspace/${id}`);
    dispatch(getWorkSpaces());
  };
};
export const getWorkSpaces = (workspace) => {
  return async (dispatch) => {
    try {
      const result = await jwtAxios.get('/workspaces');
      if (result.status == 200 && result.data.code == 200) {
        dispatch({type: GET_WORKSPACES, payload: result.data.data});
      }
    } catch (error) {
      console.log(error);
      dispatch({type: FETCH_ERROR, payload: error.message});
    }
  };
};

//Project
export const addProject = ({name, type, parentId}) => {
  return async (dispatch) => {
    const offset = new Date().getTimezoneOffset() * 60000;
    const today = new Date(Date.now() - offset);
    const item = {
      name: name,
      createdAt: today.toISOString(),
      type: type,
      parentId: parentId,
    };
    try {
      console.log(item);
      const result = await Api.put('project', item);
      if (result.status == 200 && result.data.code == 200) {
        dispatch(getProjects(parentId));
      }
    } catch (error) {
      console.log(error);
      dispatch({type: FETCH_ERROR, payload: error.message});
    }
  };
};

export const getProject = (id) => {
  return async (dispatch) => {
    try {
      const result = await Api.get(`/projects/${id}`);
      if (result.status == 200 && result.data.code == 200) {
        dispatch({type: GET_PROJECT, payload: result.data.data});
      }
    } catch (error) {
      console.log(error);
    }
  };
};
export const updateProject = (id, project) => {
  return async (dispatch) => {
    try {
      await Api.post(`/project/${id}`, project);
      dispatch(getProject(id));
    } catch (error) {
      console.log(error);
    }
  };
};
export const deleteProject = (projectId, parentId) => {
  return async (dispatch) => {
    try {
      await Api.delete(`/project/${projectId}`);
      dispatch(getProjects(parentId));
    } catch (error) {
      console.log(error);
    }
  };
};
export const getProjects = (id) => {
  return async (dispatch) => {
    const result = await Api.get(`/workspaces/${id}/projects`);
    if (result.status == 200 && result.data.code == 200) {
      dispatch({type: GET_PROJECTS, payload: result.data.data});
    }
  };
};

export const setProject = (project) => {
  return async (dispatch) => {
    dispatch({type: SET_PROJECT, payload: project});
  };
};
