import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  GET_CONNECTIONS_LIST,
  REMOVEALL_FILES,
  REMOVE_FILE,
  SEND_FILE_TO_SERVER,
  SET_FILES,
} from '../../shared/constants/ActionTypes';
import Api from '../../@crema/services/ApiConfig';
import {appIntl} from '../../@crema/utility/helper/Utils';
import {useSelector} from 'react-redux';

export const setImageFileList = (imageList) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: SET_FILES, payload: imageList});
  };
};
export const removeImageFile = (image) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: REMOVE_FILE, payload: image});
  };
};

export const sendImageListToServer = (imageList) => {
  const {messages} = appIntl();
  return (dispatch) => {
    console.log('sendImageListToServer');
    const formData = new FormData();
    for (const file of imageList) {
      formData.append('files', file, file.name);
    }

    Api.put('/file', formData)
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: GET_CONNECTIONS_LIST, payload: data.data});
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong'],
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.message});
      });
    dispatch({type: SEND_FILE_TO_SERVER});
  };
};

export const removeAllImageList = () => {
  return (dispatch) => {
    dispatch({type: REMOVEALL_FILES});
  };
};

export const getConnectionList = () => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    Api.get('/api/chatApp/connections')
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: GET_CONNECTIONS_LIST, payload: data.data});
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong'],
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.message});
      });
  };
};
