import React, {createContext, useContext, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
} from '../../../../shared/constants/ActionTypes';
import jwtAxios, {setAuthToken} from './jwt-api';

const JWTAuthContext = createContext();
const JWTAuthActionsContext = createContext();

export const useJWTAuth = () => useContext(JWTAuthContext);

export const useJWTAuthActions = () => useContext(JWTAuthActionsContext);

const JWTAuthAuthProvider = ({children}) => {
  const [jwtAuthData, setJWTAuthData] = useState({
    user: null,
    isAuthenticated: false,
    isLoading: true,
  });

  const dispatch = useDispatch();

  useEffect(() => {
    const getAuthUser = () => {
      const token = localStorage.getItem('token');

      if (!token) {
        setJWTAuthData({
          user: undefined,
          isLoading: false,
          isAuthenticated: false,
        });
        return;
      }
      setAuthToken(token);
      jwtAxios
        .get('/user')
        .then(({data}) => {
          if (data.detail) {
            setJWTAuthData({
              user: undefined,
              isLoading: false,
              isAuthenticated: false,
            });
          } else {
            setJWTAuthData({
              user: data,
              isLoading: false,
              isAuthenticated: true,
            });
          }
        })
        .catch(() =>
          setJWTAuthData({
            user: undefined,
            isLoading: false,
            isAuthenticated: false,
          }),
        );
    };

    getAuthUser();
  }, []);

  const signInUser = async ({email, password}) => {
    dispatch({type: FETCH_START});
    try {
      const {data} = await jwtAxios.post('signin', {email, password});
      if (data.code != '200') {
        throw new Error(data.msg);
      }
      if (localStorage.getItem('checked') === 'true') {
        localStorage.setItem('email', email);
      } else {
        localStorage.removeItem('email');
      }
      localStorage.setItem('token', data.access_token);
      setAuthToken(data.access_token);
      const res = await jwtAxios.get('/user');

      setJWTAuthData({
        user: res.data,
        isAuthenticated: true,
        isLoading: false,
      });
      dispatch({type: FETCH_SUCCESS});
    } catch (error) {
      setJWTAuthData({
        ...jwtAuthData,
        isAuthenticated: false,
        isLoading: false,
      });
      dispatch({type: FETCH_ERROR, payload: error.message});
    }
  };

  const signUpUser = async ({name, email, password}) => {
    dispatch({type: FETCH_START});
    try {
      const {data} = await jwtAxios.put('signup', {name, email, password});
      if (data.code != 200) {
        throw new Error(data.msg);
      }
      localStorage.setItem('token', data.token);
      setAuthToken(data.access_token);
      const res = await jwtAxios.get('/user');
      setJWTAuthData({user: res.data, isAuthenticated: true, isLoading: false});
      dispatch({type: FETCH_SUCCESS});
    } catch (error) {
      setJWTAuthData({
        ...jwtAuthData,
        isAuthenticated: false,
        isLoading: false,
      });
      dispatch({type: FETCH_ERROR, payload: error.message});
    }
  };

  const logout = async () => {
    localStorage.removeItem('token');
    setAuthToken();
    setJWTAuthData({
      user: null,
      isLoading: false,
      isAuthenticated: false,
    });
  };

  const passwordChange = async (password, new_password) => {
    dispatch({type: FETCH_START});
    try {
      const {data} = await jwtAxios.post('change-password', {
        password,
        new_password,
      });
      if (data.code === 200000) {
        alert('비밀번호가 변경되었습니다!');
      } else {
        throw new Error(data.message);
      }
    } catch (error) {
      alert('기존 비밀번호와 다릅니다!');
      dispatch({type: FETCH_ERROR, payload: error.message});
    }
  };

  return (
    <JWTAuthContext.Provider
      value={{
        ...jwtAuthData,
      }}>
      <JWTAuthActionsContext.Provider
        value={{
          signUpUser,
          signInUser,
          logout,
          passwordChange,
        }}>
        {children}
      </JWTAuthActionsContext.Provider>
    </JWTAuthContext.Provider>
  );
};
export default JWTAuthAuthProvider;

JWTAuthAuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
