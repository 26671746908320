import {
  ADD_WORKSPACE,
  GET_WORKSPACE,
  UPDATE_WORKSPACE,
  DELETE_WORKSPACE,
  GET_WORKSPACES,
  ADD_PROJECT,
  GET_PROJECT,
  GET_PROJECTS,
  UPDATE_PROJECT,
  DELETE_PROJECT,
  SET_PROJECT,
  ADD_TRAIN,
  GET_TRAIN,
  UPDATE_TRAIN,
  DELETE_TRAIN,
  GET_TRAINS,
  ADD_TEST,
  GET_TEST,
  UPDATE_TEST,
  DELETE_TEST,
  GET_TESTS,
  SET_WORKSPACE_SELECTED_ITEM,
  UPDATE_TRAIN_STATUS,
  UPDATE_TEST_STATUS,
} from '../../shared/constants/ActionTypes';

const initialState = {
  project: {
    name: '생활환경 위험요소 탐지',
    created: '곽근명',
    createdAt: '2022-12-01 12:34:00',
    type: 'ObjectDetection',
    parentId: '1',
  },
  selectedItem: 'aaaa',
  workspace: [],
  projects: [
    // {
    //   name: '생활환경 위험요소 탐지',
    //   created: '곽근명',
    //   createdAt: '2022-12-01 12:34:00',
    //   type: 'ObjectDetection',
    //   parentId: '1',
    // },
    // {
    //   name: '컨텍스트 공간정보',
    //   created: '곽근명',
    //   createdAt: '2022-12-01 12:34:00',
    //   type: 'ImageCaptioning',
    //   parentId: '1',
    // },
    // {
    //   name: '생활환경 위험요소 탐지2',
    //   created: '곽근명',
    //   createdAt: '2022-12-01 12:34:00',
    //   type: 'ObjectDetection',
    //   parentId: '2',
    // },
    // {
    //   name: '컨텍스트 공간정보2',
    //   created: '곽근명',
    //   createdAt: '2022-12-01 12:34:00',
    //   type: 'ImageCaptioning',
    //   parentId: '2',
    // },
  ],
  trains: [
    // {
    //   key: '1',
    //   createdAt: 1670889451245,
    //   duration: 120000,
    //   runName: '00_344_999999',
    //   status: 'ready',
    //   images: 2000,
    //   anootation: 38205,
    //   result: 'result',
    //   dataset: 'Dataset',
    //   created: '곽근명',
    //   parameter: {
    //     leaningRate: 0.02,
    //     epoch: 20,
    //     trainVal: '8:1',
    //     classes: '"_background_", "waste"',
    //     model: 'RetinaNet',
    //     remaining: 0,
    //   },
    // },
    // {
    //   key: '2',
    //   createdAt: 1670809451245,
    //   duration: 120000,
    //   runName: '00_344_999998',
    //   status: 'progress',
    //   images: 3000,
    //   anootation: 38205,
    //   result: 'result',
    //   dataset: 'Dataset',
    //   created: '곽근명',
    //   parameter: {
    //     leaningRate: 0.02,
    //     epoch: 20,
    //     trainVal: '8:1',
    //     classes: '"_background_", "waste"',
    //     model: 'RetinaNet',
    //     remaining: 21600,
    //   },
    // },
    // {
    //   key: '3',
    //   createdAt: 1668128697526,
    //   duration: 120000,
    //   runName: '00_344_999997',
    //   status: 'Finish',
    //   images: 4000,
    //   anootation: 38205,
    //   result: 'result',
    //   dataset: 'Dataset',
    //   created: '곽근명',
    //   parameter: {
    //     leaningRate: 0.02,
    //     epoch: 20,
    //     trainVal: '8:1',
    //     classes: '"_background_", "waste"',
    //     model: 'RetinaNet',
    //     remaining: 21600,
    //   },
    // },
  ],
  tests: [
    // {
    //   key: '1',
    //   createdAt: 1670889451245,
    //   duration: 120000,
    //   models: 'trash',
    //   dataset: 'dataset',
    //   runName: '00_344_999999',
    //   status: 'finish',
    //   images: 200,
    //   result: 'result',
    // },
    // {
    //   key: '2',
    //   createdAt: 1670809451245,
    //   duration: 120000,
    //   models: 'plasticbag',
    //   dataset: 'dataset2',
    //   runName: '00_344_999998',
    //   status: 'progress',
    //   images: 100,
    //   result: 'result',
    // },
    // {
    //   key: '3',
    //   createdAt: 1668128697526,
    //   duration: 120000,
    //   models: 'trash',
    //   dataset: 'dataset',
    //   runName: '00_344_999997',
    //   status: 'ready',
    //   images: 300,
    //   result: 'result',
    // },
  ],
};

const Workspace = (state = initialState, action) => {
  switch (action.type) {
    case ADD_WORKSPACE:
      return {
        ...state,
        workspace: [...state.workspace, ...action.payload],
      };
    case GET_WORKSPACE: {
      return {
        ...state,
      };
    }
    case UPDATE_WORKSPACE: {
      return {
        ...state,
        workspace: state.workspace.map((item) =>
          item.id === action.payload.id ? action.payload : item,
        ),
      };
    }
    case DELETE_WORKSPACE: {
      let workspace = [...state.workspace];
      const idx = workspace.indexOf(action.payload);
      if (idx > -1) workspace.splice(idx, 1);
      return {
        ...state,
        workspace: workspace,
      };
    }
    case GET_WORKSPACES: {
      return {
        ...state,
        workspace: [...action.payload],
      };
    }
    case SET_WORKSPACE_SELECTED_ITEM: {
      return {
        ...state,
        selectedItem: action.payload,
      };
    }
    case ADD_PROJECT:
      return {
        ...state,
        project: [...state.project, ...action.payload],
      };
    case GET_PROJECT: {
      return {
        ...state,
        project: action.payload,
      };
    }
    case GET_PROJECTS: {
      return {
        ...state,
        projects: [...action.payload],
      };
    }
    case UPDATE_PROJECT: {
      return {
        ...state,
        project: state.project.map((item) =>
          item.id === action.payload.id ? action.payload : item,
        ),
      };
    }
    case DELETE_PROJECT: {
      let project = [...state.project];
      const idx = project.indexOf(action.payload);
      if (idx > -1) project.splice(idx, 1);
      return {
        ...state,
        project: project,
      };
    }
    case SET_PROJECT: {
      return {
        ...state,
        selectedProject: action.payload,
      };
    }
    case ADD_TRAIN: {
      return {
        ...state,
        experiments: [...state.experiments, ...action.payload],
      };
    }
    case GET_TRAIN: {
      return {
        ...state,
      };
    }
    case UPDATE_TRAIN: {
      return {
        ...state,
        experiments: state.experiments.map((item) =>
          item.id === action.payload.id ? action.payload : item,
        ),
      };
    }
    case DELETE_TRAIN: {
      let experiments = [...state.experiments];
      const idx = experiments.indexOf(action.payload);
      if (idx > -1) experiments.splice(idx, 1);
      return {
        ...state,
        experiments: experiments,
      };
    }
    case UPDATE_TRAIN_STATUS: {
      console.log(state);
      const duplicatedTrainsInState = [...state.trains];
      const {id, status} = action.payload;

      const updatedTrainsInState = duplicatedTrainsInState.map((train) => {
        if (train._id === id) {
          return {
            ...train,
            status,
          };
        }
        return train;
      });

      return {
        ...state,
        trains: updatedTrainsInState,
      };
    }
    case GET_TRAINS: {
      return {
        ...state,
        trains: [...action.payload],
      };
    }
    case ADD_TEST:
      return {
        ...state,
        tests: [...state.tests, ...action.payload],
      };
    case GET_TEST: {
      return {
        ...state,
      };
    }
    case UPDATE_TEST: {
      return {
        ...state,
        tests: state.train.map((item) =>
          item.id === action.payload.id ? action.payload : item,
        ),
      };
    }
    case UPDATE_TEST_STATUS: {
      console.log(state);
      const duplicatedTestsInState = [...state.tests];
      const {id, status} = action.payload;

      const updatedTestsInState = duplicatedTestsInState.map((test) => {
        if (test._id === id) {
          return {
            ...test,
            status,
          };
        }
        return test;
      });

      return {
        ...state,
        tests: updatedTestsInState,
      };
    }
    case DELETE_TEST: {
      let tests = [...state.tests];
      const idx = tests.indexOf(action.payload);
      if (idx > -1) tests.splice(idx, 1);
      return {
        ...state,
        tests: tests,
      };
    }
    case GET_TESTS: {
      return {
        ...state,
        tests: [...action.payload],
      };
    }
    default:
      return state;
  }
};
export default Workspace;
