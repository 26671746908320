import React from 'react';

const MyPage = React.lazy(() => import('./myPage'));
const ObjectDetection = React.lazy(() => import('./objectDetection'));
const ImageCaptioning = React.lazy(() => import('./imageCaptioning'));
const Result = React.lazy(() => import('./objectDetection/Test/Result'));
const ResultIC = React.lazy(() => import('./imageCaptioning/Test/Result'));

export const ObjectDetectionConfig = [
  {
    path: '/myPage',
    element: <MyPage />,
  },
  {
    path: '/objectDetection/:workspaceId/:projectId',
    element: <ObjectDetection />,
  },
  {
    path: '/objectDetection/:workspaceId/:projectId/:location',
    element: <ObjectDetection />,
  },
  {
    path: '/objectDetection/result/:name/:workspaceId/:projectId/:testId',
    element: <Result />,
  },
  {
    path: '/imageCaptioning/:workspaceId/:projectId',
    element: <ImageCaptioning />,
  },
  {
    path: '/imageCaptioning/:workspaceId/:projectId/:location',
    element: <ImageCaptioning />,
  },
  {
    path: '/imageCaptioning/result/:name/:workspaceId/:projectId/:testId',
    element: <ResultIC />,
  },
];
