import {
  GET_COWORKS,
  SET_WORKSPACES_FOR_COWORKER,
} from '@shared/constants/ActionTypes';

const initialState = {
  coworker: [],
  workspaces: [],
};

const cowerkerReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_COWORKS:
      return {
        ...state,
        coworker: action.payload,
      };
    case SET_WORKSPACES_FOR_COWORKER:
      return {
        ...state,
        workspaces: action.payload,
      };

    default:
      return state;
  }
};
export default cowerkerReducer;
