import {
  SET_TRAIN_FILES,
  FETCH_SUCCESS,
  FETCH_ERROR,
  SET_VAL_FILES,
  SET_TEST_FILES,
  DELETE_TRAIN,
  GET_TRAINS,
  SET_TRAIN,
  SET_TRAIN_FILE,
  UPDATE_TRAIN_STATUS,
} from '../../shared/constants/ActionTypes';
import Api from '../../@crema/services/ApiConfig';
import {appIntl} from '../../@crema/utility/helper/Utils';
import {getTest} from './Test';
import {IoMdSkipForward} from 'react-icons/io';

export const addTrainIC = (parentId, values, name) => {
  const {messages} = appIntl();
  return async (dispatch) => {
    try {
      const offset = new Date().getTimezoneOffset() * 60000;
      const today = new Date(Date.now() - offset);

      const result = await Api.post('/train-ics', {
        ...values,
        // runName: `imageCaptioning-${new Date().toISOString()}`,
        runName: name,
        parentId: parentId,
        createdAt: today.toISOString(),
      });
      console.log(result);

      if (result.status == 200) {
        dispatch({type: FETCH_SUCCESS});
        dispatch(getTrainIC(result.data.data));
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: messages['message.somethingWentWrong'],
        });
      }
    } catch (error) {
      console.log(error);
      dispatch({type: FETCH_ERROR, payload: error.message});
    }
  };
};
export const getTrainIC = (id) => {
  const {messages} = appIntl();
  return async (dispatch) => {
    try {
      const result = await Api.get(`/train-ics/${id}`);
      console.log('RESULT:' + result.status);
      if (result.status == 200) {
        dispatch({type: FETCH_SUCCESS});
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: messages['message.somethingWentWrong'],
        });
      }
      dispatch({type: SET_TRAIN, payload: result.data});
    } catch (error) {
      console.log(error);
      dispatch({type: FETCH_ERROR, payload: error.message});
    }
  };
};

export const getTrainFiles = (parentId) => {
  const {messages} = appIntl();
  return (dispatch) => {
    Api.get(`/folders/${parentId}/files`).then(({data}) => {
      // Api.post(`/files/${parentId}`).then(({data}) => {
      if (data.code === '200') {
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: SET_TRAIN_FILES, payload: data.data});
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: messages['message.somethingWentWrong'],
        });
      }
    });
  };
};

export const getValFiles = (parentId) => {
  const {messages} = appIntl();
  return (dispatch) => {
    Api.get(`/folders/${parentId}/files`).then(({data}) => {
      if (data.code === '200') {
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: SET_VAL_FILES, payload: data.data});
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: messages['message.somethingWentWrong'],
        });
      }
    });
  };
};

export const getTestFiles = (parentId) => {
  const {messages} = appIntl();
  return (dispatch) => {
    Api.get(`/folders/${parentId}/files`).then(({data}) => {
      if (data.code === '200') {
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: SET_TEST_FILES, payload: data.data});
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: messages['message.somethingWentWrong'],
        });
      }
    });
  };
};

export const updateTrainName = (id, name) => {
  return async (dispatch) => {
    try {
      const {data} = await Api.post(`/train/${id}/name`, name);
      if (Number(data.code) === 200) {
        dispatch({type: FETCH_SUCCESS});
      } else {
        throw new Error('IC train name update error');
      }
    } catch (error) {
      console.log(error);
      dispatch({type: FETCH_ERROR, payload: error.message});
    }
  };
};

export const updateTrainIC = (id, values) => {
  const {messages} = appIntl();
  return async (dispatch) => {
    try {
      const {status} = await Api.put(`/train-ics/${id}`, values);
      if (Number(status) === 200) {
        dispatch({type: FETCH_SUCCESS});
      } else {
        throw new Error('train IC update error');
      }
    } catch (error) {
      console.log(error);
      dispatch({
        type: FETCH_ERROR,
        payload: messages['message.somethingWentWrong'],
      });
    }
  };
};

export const deleteTrain = (train) => {
  return (dispatch) => {
    dispatch({type: DELETE_TRAIN, payload: train});
  };
};
export const getTrainICs = (id) => {
  const {messages} = appIntl();
  return async (dispatch) => {
    try {
      const result = await Api.get(`/train-ics?id=${id}`);
      if (result.status == 200) {
        dispatch({type: FETCH_SUCCESS});
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: messages['message.somethingWentWrong'],
        });
      }
      dispatch({type: GET_TRAINS, payload: result.data.data});
    } catch (error) {
      console.log(error);
      dispatch({type: FETCH_ERROR, payload: error.message});
    }
  };
};

export const startTrainIC = (id) => {
  return Api.post(`/trainICStart?id=${id}`);
};

export const stopTrain = (id) => {
  return async (dispatch) => {
    try {
      const {data} = await Api.post(`/trainStop?id=${id}`);
      if (Number(data.code) === 200) {
        dispatch({type: FETCH_SUCCESS});
      } else {
        throw new Error('Train cannot be stopped');
      }
    } catch (error) {
      console.log(error);
      dispatch({type: FETCH_ERROR, payload: error.message});
    }
  };
};
export const getTrainFile = (trainId, fileId) => {
  return async (dispatch) => {
    try {
      const result = await Api.get(
        `/trainFile?trainId=${trainId}&fileId=${fileId}`,
      );
      if (Number(result.data.code) === 200) {
        dispatch({type: SET_TRAIN_FILE, payload: result.data});
        console.log(result);
      } else {
        throw new Error('train file cannot be received');
      }
    } catch (error) {
      console.log(error);
      dispatch({type: FETCH_ERROR, payload: error.message});
    }
  };
};
