import {
  SET_TRAIN_FILES,
  FETCH_SUCCESS,
  FETCH_ERROR,
  SET_VAL_FILES,
  SET_TEST_FILES,
  DELETE_TRAIN,
  GET_TRAINS,
  SET_TRAIN,
  SET_TRAIN_FILE,
} from '../../shared/constants/ActionTypes';
import Api from '../../@crema/services/ApiConfig';
import {appIntl} from '../../@crema/utility/helper/Utils';

export const addTrain = (parentId, values, name) => {
  const {messages} = appIntl();
  return async (dispatch) => {
    try {
      const offset = new Date().getTimezoneOffset() * 60000;
      const today = new Date(Date.now() - offset);

      const result = await Api.post('/trains', {
        ...values,
        // runName: `${values.model}-${new Date().toISOString()}`,
        runName: name,
        parentId: parentId,
        createdAt: today.toISOString(),
      });
      console.log(result);

      if (result.status == 200) {
        dispatch({type: FETCH_SUCCESS});
        dispatch(getTrain(result.data.data));
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: messages['message.somethingWentWrong'],
        });
      }
    } catch (error) {
      console.log(error);
      dispatch({type: FETCH_ERROR, payload: error.message});
    }
  };
};
export const getTrain = (id) => {
  const {messages} = appIntl();
  return async (dispatch) => {
    try {
      if (!id) return dispatch({type: SET_TRAIN, payload: {}});
      const result = await Api.get(`/trains/${id}`);
      if (result.status == 200) {
        dispatch({type: FETCH_SUCCESS});
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: messages['message.somethingWentWrong'],
        });
      }
      dispatch({type: SET_TRAIN, payload: result.data});
    } catch (error) {
      console.log(error);
      dispatch({type: FETCH_ERROR, payload: error.message});
    }
  };
};

export const getTrainFiles = (datasetId) => {
  const {messages} = appIntl();
  return (dispatch) => {
    if (!datasetId) {
      return dispatch({type: SET_TRAIN_FILES, payload: []});
    }
    Api.get(`/folders/${datasetId}/files`).then(({data}) => {
      if (data.code === '200') {
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: SET_TRAIN_FILES, payload: data.data});
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: messages['message.somethingWentWrong'],
        });
      }
    });
  };
};

export const getValFiles = (datasetId) => {
  const {messages} = appIntl();
  return (dispatch) => {
    if (!datasetId) {
      return dispatch({type: SET_VAL_FILES, payload: []});
    }
    Api.get(`/folders/${datasetId}/files`).then(({data}) => {
      if (data.code === '200') {
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: SET_VAL_FILES, payload: data.data});
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: messages['message.somethingWentWrong'],
        });
      }
    });
  };
};

export const getTestFiles = (datasetId) => {
  const {messages} = appIntl();
  return (dispatch) => {
    if (!datasetId) {
      return dispatch({type: SET_TEST_FILES, payload: []});
    }
    Api.get(`/folders/${datasetId}/files`).then(({data}) => {
      if (data.code === '200') {
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: SET_TEST_FILES, payload: data.data});
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: messages['message.somethingWentWrong'],
        });
      }
    });
  };
};

export const updateTrainName = (id, name) => {
  const {messages} = appIntl();
  return async (dispatch) => {
    try {
      const {data} = await Api.post(`/train/${id}/name`, name);
      if (Number(data.code) === 200) {
        dispatch({type: FETCH_SUCCESS});
      } else {
        throw new Error('Something went wrong');
      }
    } catch (error) {
      console.log(error);
      dispatch({
        type: FETCH_ERROR,
        payload: error.message,
      });
    }
  };
};

export const updateTrain = (id, values) => {
  const {messages} = appIntl();
  return async (dispatch) => {
    try {
      const {status} = await Api.put(`/trains/${id}`, values);
      if (status === 200) {
        dispatch({type: FETCH_SUCCESS});
      } else {
        throw new Error('Something went wrong');
      }
    } catch (error) {
      console.log(error);
      dispatch({
        type: FETCH_ERROR,
        payload: messages['useMessage.somethingWentWrong'],
      });
    }
  };
};

export const deleteTrain = (projectId, trainList) => {
  return async (dispatch) => {
    const results = [];
    for (const key of trainList) {
      console.log(key);
      const result = await Api.delete(`/trains/${key}`);
      results.push(result);
    }
    console.log(results);
    const errorMsgList = [];
    for (const {data} of results) {
      console.log(data);
      if (data.code == '500') {
        errorMsgList.push('사용중인 추론(테스트)이 있습니다.');
      }
    }
    const errorMsg = errorMsgList.join('\n');
    console.log(errorMsg);
    dispatch({type: FETCH_ERROR, payload: errorMsg});
    dispatch(getTrains(projectId));
  };
};

export const getTrains = (project_id) => {
  return async (dispatch) => {
    const {messages} = appIntl();
    try {
      const result = await Api.get(`/projects/${project_id}/trains`);
      if (result.status == 200) {
        dispatch({type: FETCH_SUCCESS});
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: messages['message.somethingWentWrong'],
        });
      }
      dispatch({type: GET_TRAINS, payload: result.data.data});
    } catch (error) {
      console.log(error);
      dispatch({
        type: FETCH_ERROR,
        payload: messages['message.somethingWentWrong'],
      });
    }
  };
};

export const startTrain = (id) => {
  return Api.post(`/trainStart?id=${id}`);
};

export const stopTrain = (id) => {
  return async (dispatch) => {
    try {
      const {data} = await Api.post(`/trainStop?id=${id}`);
      if (Number(data.code) === 200) {
        dispatch({type: FETCH_SUCCESS});
      } else {
        throw new Error('Train stop failure');
      }
    } catch (error) {
      console.log(error);
      dispatch({type: FETCH_ERROR, payload: error.message});
    }
  };
};
export const getTrainFile = (trainId, fileId) => {
  const messages = appIntl();
  return async (dispatch) => {
    try {
      const result = await Api.get(
        `/trainFile?trainId=${trainId}&fileId=${fileId}`,
      );
      if (Number(result.data.code) === 200) {
        dispatch({type: SET_TRAIN_FILE, payload: result.data});
        console.log(result);
      } else {
        throw new Error('Train File cannot receivable');
      }
    } catch (error) {
      console.log(error);
      dispatch({
        type: FETCH_ERROR,
        payload: messages['message.somethingWentWrong'],
      });
    }
  };
};
