import koMessages from '../locales/ko_KR.json';
import koKR from 'antd/lib/locale/ko_KR';

const koLang = {
  messages: {
    ...koMessages,
  },
  antLocale: koKR,
  locale: 'kr',
};
export default koLang;
