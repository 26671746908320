import axios from 'axios';

const serverURL = process.env.REACT_APP_SERVER_URL;
export default axios.create({
  baseURL: serverURL,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
});
