import React from 'react';
import {RoutePermittedRole} from '../../shared/constants/AppEnums';

const Landing = React.lazy(() => import('./landing2'));

export const dashConfig = [
  {
    permittedRole: RoutePermittedRole.user,
    path: '/dash',
    element: <Landing />,
  },
];
