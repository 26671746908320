import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  GET_COWORKS,
  SET_WORKSPACES_FOR_COWORKER,
} from '@shared/constants/ActionTypes';
import Api from '../../@crema/services/ApiConfig';
import IntlMessages from '../../@crema/utility/IntlMessages';
import jwtAxios from '@crema/services/auth/jwt-auth/jwt-api';
import React from 'react';

export const GetCoworkers = (workspaceId) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    Api.get(`workspaces/${workspaceId}/coworkers`)
      .then(({data}) => {
        if (data.code === '200') {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: GET_COWORKS, payload: data.data});
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: <IntlMessages id='message.somethingWentWrong' />,
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.message});
      });
  };
};
export const addCoworkers = ({workspaceId, users}) => {
  return async (dispatch) => {
    const offset = new Date().getTimezoneOffset() * 60000;
    const today = new Date(Date.now() - offset);

    const item = {
      workspace: workspaceId,
      users: users,
      createdAt: today,
    };

    dispatch({type: FETCH_START});
    Api.put('/coworkers', item)
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: <IntlMessages id='message.somethingWentWrong' />,
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.message});
      });
  };
};

export const removeCoworker = (id) => {
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    Api.delete(`/coworker?id=${id}`)
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: <IntlMessages id='message.somethingWentWrong' />,
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.message});
      });
  };
};

export const addCoworker = ({workspaceId, user}) => {
  return async (dispatch) => {
    const item = {
      workspace: workspaceId,
      user: user,
      createdAt: new Date(),
    };

    dispatch({type: FETCH_START});
    Api.put('/coworker', item)
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: <IntlMessages id='message.somethingWentWrong' />,
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.message});
      });
  };
};
export const getWorkSpacesForCoworker = (workspace) => {
  return async (dispatch) => {
    try {
      const result = await jwtAxios.get('/co-workspaces');
      if (result.status == 200 && result.data.code == 200) {
        dispatch({
          type: SET_WORKSPACES_FOR_COWORKER,
          payload: result.data.data,
        });
      }
    } catch (error) {
      console.log(error);
      dispatch({type: FETCH_ERROR, payload: error.message});
    }
  };
};
