import {SET_TRAIN, SET_TRAIN_FILE} from '../../shared/constants/ActionTypes';

const initialState = {
  train: {},
  file: '',
};

const trainReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_TRAIN:
      return {
        ...state,
        train: action.payload,
      };
    case SET_TRAIN_FILE:
      return {
        ...state,
        file: action.payload,
      };
    default:
      return state;
  }
};
export default trainReducer;
