import {
  FETCH_SUCCESS,
  FETCH_ERROR,
  SET_TEST,
  DELETE_TEST,
  GET_TESTS,
} from '../../shared/constants/ActionTypes';
import Api from '../../@crema/services/ApiConfig';
import {appIntl} from '../../@crema/utility/helper/Utils';
import jwtAxios from '@crema/services/auth/jwt-auth/jwt-api';

export const getTest = (id) => {
  const {messages} = appIntl();
  return async (dispatch) => {
    try {
      const result = await Api.get(`/tests/${id}`);
      if (result.status == 200) {
        dispatch({type: FETCH_SUCCESS});
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: messages['message.somethingWentWrong'],
        });
      }
      dispatch({type: SET_TEST, payload: result.data});
    } catch (error) {
      console.log(error);
      dispatch({type: FETCH_ERROR, payload: error.message});
    }
  };
};

export const addTest = (parentId, values) => {
  const {messages} = appIntl();
  return async (dispatch) => {
    try {
      const offset = new Date().getTimezoneOffset() * 60000;
      const today = new Date(Date.now() - offset);

      const result = await jwtAxios.post('/tests', {
        ...values,
        parentId: parentId,
        createdAt: today.toISOString(),
      });
      console.log(result);

      if (result.status == 200) {
        dispatch({type: FETCH_SUCCESS});
        dispatch(getTest(result.data.data));
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: messages['message.somethingWentWrong'],
        });
      }
    } catch (error) {
      console.log(error);
      dispatch({type: FETCH_ERROR, payload: error.message});
    }
  };
};

export const updateTest = (testId, values) => {
  return async (dispatch) => {
    const {messages} = appIntl();
    try {
      const result = await Api.put(`/tests/${testId}`, values);
      if (result.status == 200) {
        dispatch({type: FETCH_SUCCESS});
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: messages['message.somethingWentWrong'],
        });
      }
    } catch (error) {
      console.log(error);
      dispatch({type: FETCH_ERROR, payload: error.message});
    }
  };
};

export const updateTestName = (id, name) => {
  return async (dispatch) => {
    try {
      const {data} = await Api.post(`/test/${id}/name`, name);
      if (Number(data.code) === 200) {
        dispatch({type: FETCH_SUCCESS});
      } else {
        throw new Error('Test Name update error');
      }
    } catch (error) {
      console.log(error);
      dispatch({type: FETCH_ERROR, payload: error.message});
    }
  };
};

export const deleteTest = (projectId, testList) => {
  return async (dispatch) => {
    const results = [];
    for (const key of testList) {
      console.log(key);
      const result = await Api.delete(`/tests/${key}`);
      results.push(result);
    }
    console.log(results);
    const errorMsgList = [];
    for (const {data} of results) {
      console.log(data);
      if (data.code == '500') {
        errorMsgList.push('진행중인 프로젝트는 삭제할수 없습니다.');
      }
    }
    const errorMsg = errorMsgList.join('\n');
    console.log(errorMsg);
    dispatch({type: FETCH_ERROR, payload: errorMsg});
    dispatch(getTests(projectId));
  };
};

export const getTests = (project_id) => {
  return async (dispatch) => {
    const {messages} = appIntl();
    try {
      const result = await Api.get(`/projects/${project_id}/tests`);
      if (result.status == 200) {
        dispatch({type: FETCH_SUCCESS});
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: messages['message.somethingWentWrong'],
        });
      }
      dispatch({type: GET_TESTS, payload: result.data.data});
    } catch (error) {
      console.log(error);
      dispatch({type: FETCH_ERROR, payload: error.message});
    }
  };
};

export const startTest = (id) => {
  return Api.post(`/testStart?id=${id}`);
};

export const stopTest = (id) => {
  return async (dispatch) => {
    try {
      const {data} = await Api.post(`/testStop?id=${id}`);
      if (Number(data.code) === 200) {
        dispatch({type: FETCH_SUCCESS});
      } else {
        throw new Error('Test cannot be stopped');
      }
    } catch (error) {
      console.log(error);
      dispatch({type: FETCH_ERROR, payload: error.message});
    }
  };
};
