import {
  SET_FILE_MAP,
  SET_CURRENT_FOLDER,
  SET_FM_FOLDERCHAIN,
  SET_FM_FILES,
  SET_FM_DATASET,
  SET_TRAIN_FILES,
  SET_VAL_FILES,
  SET_TEST_FILES,
} from '../../shared/constants/ActionTypes';

const initialState = {
  currentFolder: '0',
  files: [],
  folderChain: [],
  datasets: [],
  train: [],
  val: [],
  test: [],
};

const fileUploardReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_FILE_MAP:
      return {
        ...state,
        fileMap: action.payload,
      };
    case SET_CURRENT_FOLDER:
      return {
        ...state,
        fileMap: action.payload,
      };
    case SET_FM_FOLDERCHAIN:
      return {
        ...state,
        folderChain: action.payload,
      };
    case SET_FM_FILES:
      return {
        ...state,
        files: action.payload,
      };
    case SET_TRAIN_FILES:
      return {
        ...state,
        train: action.payload,
      };
    case SET_VAL_FILES:
      return {
        ...state,
        val: action.payload,
      };
    case SET_TEST_FILES:
      return {
        ...state,
        test: action.payload,
      };
    case SET_FM_DATASET:
      return {
        ...state,
        datasets: action.payload,
      };

    default:
      return state;
  }
};
export default fileUploardReducer;
