import {
  FETCH_SUCCESS,
  FETCH_ERROR,
  SET_TEST,
  DELETE_TEST,
  GET_TESTS,
} from '../../shared/constants/ActionTypes';
import Api from '../../@crema/services/ApiConfig';
import {appIntl} from '../../@crema/utility/helper/Utils';

// export const startTestIC = (id) => {
//   const {messages} = appIntl();
//   return async (dispatch) => {
//     try {
//       const {data} = await Api.post(`/testICStart?id=${id}`);
//       if (Number(data.code) === 200) {
//         dispatch({type: FETCH_SUCCESS});
//       } else {
//         throw new Error('Test cannot be started');
//       }
//     } catch (error) {
//       console.log(error);
//     }
//   };
// };

export const startTestIC = async (id) => {
  return await Api.post(`/testICStart?id=${id}`);
};

export const getTestIC = (id) => {
  const {messages} = appIntl();
  return async (dispatch) => {
    try {
      const result = await Api.get(`/testIC/${id}`);
      if (result.status == 200) {
        dispatch({type: FETCH_SUCCESS});
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: messages['message.somethingWentWrong'],
        });
      }
      dispatch({type: SET_TEST, payload: result.data});
    } catch (error) {
      console.log(error);
      dispatch({type: FETCH_ERROR, payload: error.message});
    }
  };
};
