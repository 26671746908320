import {RoutePermittedRole} from '../shared/constants/AppEnums';

const routesConfig = [
  {
    id: 'project',
    title: 'Project',
    messageId: 'sidebar.project',
    path: '/myPage',
  },
  {
    id: 'guide',
    title: 'Guide',
    messageId: 'sidebar.guide',
    path: '/guide',
  },
];
export default routesConfig;
