import {SET_TEST, SET_TEST_FILE} from '../../shared/constants/ActionTypes';

const initialState = {
  test: {},
};

const trainReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_TEST:
      return {
        ...state,
        test: action.payload,
      };
    default:
      return state;
  }
};
export default trainReducer;
