import {
  SET_CURRENT_FOLDER,
  SET_FILE_MAP,
  FETCH_SUCCESS,
  FETCH_ERROR,
  SET_FM_FILES,
  SET_FM_FOLDERCHAIN,
  SET_FM_DATASET,
} from '../../shared/constants/ActionTypes';
import Api from '../../@crema/services/ApiConfig';
import {appIntl} from '../../@crema/utility/helper/Utils';

export const setFileMap = (fileMap) => {
  return (dispatch) => dispatch({type: SET_FILE_MAP, payload: fileMap});
};
export const setCurrentFolder = (currentFolder) => {
  return (dispatch) =>
    dispatch({type: SET_CURRENT_FOLDER, payload: currentFolder});
};

export const makeFolder = (folderName, parentId) => {
  const {messages} = appIntl();
  return (dispatch) => {
    Api.put('/folder', {name: folderName, parentId: parentId}).then(
      ({data}) => {
        if (data.code === '200') {
          dispatch({type: FETCH_SUCCESS});
          dispatch(getFiles(parentId));
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong'],
          });
        }
      },
    );
  };
};

export const getFolderChain = (folderId) => {
  const {messages} = appIntl();
  return (dispatch) => {
    Api.get(`/folders/${folderId}`).then(({data}) => {
      if (data.code === '200') {
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: SET_FM_FOLDERCHAIN, payload: data.data});
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: messages['message.somethingWentWrong'],
        });
      }
    });
  };
};
export const getFolders = (folderId = '63bb85b5e94b4d73de5cc4f2') => {
  const {messages} = appIntl();
  return (dispatch) => {
    Api.post(`/folders?folderId=${folderId}`).then(({data}) => {
      if (data.code === '200') {
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: SET_FM_DATASET, payload: data.data});
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: messages['message.somethingWentWrong'],
        });
      }
    });
  };
};

export const getFiles = (parentId) => {
  const {messages} = appIntl();
  return (dispatch) => {
    // Api.get(`/folders/${parentId}/files`).then(({data}) => {
    Api.get(`/folders/${parentId}/files`).then(({data}) => {
      if (data.code === '200') {
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: SET_FM_FILES, payload: data.data});
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: messages['message.somethingWentWrong'],
        });
      }
    });
  };
};

export const moveFile = (fileId, parentId, destinationId) => {
  const {messages} = appIntl();
  return (dispatch) => {
    Api.post(`/file/${fileId}`, {
      parentId: parentId,
      destinationId: destinationId,
    }).then(({data}) => {
      if (data.code === '200') {
        dispatch({type: FETCH_SUCCESS});
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: data.msg,
        });
      }
    });
  };
};

export const renameFile = (fileId, name) => {
  const {messages} = appIntl();
  return (dispatch) => {
    Api.post(`/file/${fileId}/change-name`, {name}).then(({data}) => {
      if (data.code === 200000) {
        dispatch({type: FETCH_SUCCESS});
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: messages['message.somethingWentWrong'],
        });
      }
    });
  };
};

export const upddateFileAnnotation = (file, values) => {
  const {messages} = appIntl();
  return (dispatch) => {
    Api.post(`/file/${file.id}/annotation`, values).then(({data}) => {
      if (data.code === '200') {
        dispatch({type: FETCH_SUCCESS});
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: messages['message.somethingWentWrong'],
        });
      }
    });
  };
};

export const deleteFile = (fileId) => {
  const {messages} = appIntl();
  return (dispatch) => {
    Api.delete(`/file/${fileId}`).then(({data}) => {
      if (data.code === '200') {
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: SET_FM_FILES, payload: data.data});
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: messages['message.somethingWentWrong'],
        });
      }
    });
  };
};
