import React from 'react';
import './index.style.less';
import PropTypes from 'prop-types';
import {useSidebarContext} from '../../../../utility/AppContextProvider/SidebarContextProvider';
import {Typography} from 'antd';
import {useIntl} from 'react-intl';
import {Link} from 'react-router-dom';

const AppLogo = ({hasSidebarColor}) => {
  const {sidebarColorSet} = useSidebarContext();
  const {messages} = useIntl();
  return (
    <div className='app-logo'>
      {/* {hasSidebarColor && sidebarColorSet.mode === 'dark' ? (
        <img src='/assets/images/logo-white-with-name.png' alt='crema-logo' />
      ) : (
        <img src='/assets/images/logo-with-name.png' alt='crema-logo' />
      )} */}
      <Link to={'/dash'}>
        <Typography.Title level={2} style={{marginTop: 15, color: '#fff'}}>
          <Typography.Text style={{color: '#00DCDC'}}>L</Typography.Text>and-
          <Typography.Text style={{color: '#00DCDC'}}>XI</Typography.Text>{' '}
          플랫폼
          {/* {messages['landxi']} */}
        </Typography.Title>
      </Link>
    </div>
  );
};

export default AppLogo;

AppLogo.propTypes = {
  hasSidebarColor: PropTypes.bool,
};
