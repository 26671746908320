import React from 'react';
import {RoutePermittedRole} from '../../shared/constants/AppEnums';

const Guide = React.lazy(() => import('./Guide'));

export const guideConfig = [
  {
    permittedRole: RoutePermittedRole.user,
    path: '/guide',
    element: <Guide />,
  },
];
