import axios from 'axios';

const serverAxios = axios.create({
  baseURL: 'http://localhost:8080', //YOUR_API_URL HERE
  headers: {
    'Content-Type': 'application/json',
  },
});
// serverAxios.interceptors.response.use(
//   (res) => res,
//   (err) => {
//     if (err.response && err.response.data.msg === 'Token is not valid') {
//       console.log('Need to logout user');
//       // store.dispatch({type: LOGOUT});
//     }
//     return Promise.reject(err);
//   },
// );
// export const setAuthToken = (token) => {
//   if (token) {
//     serverAxios.defaults.headers.common['x-auth-token'] = token;
//     localStorage.setItem('token', token);
//   } else {
//     delete serverAxios.defaults.headers.common['x-auth-token'];
//     localStorage.removeItem('token');
//   }
// };

export default serverAxios;
